import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Layout from "../../components/theme/layout"
import SEO from "../../components/theme/header/seo"
import FlexSection from "../../components/reusable-components/layout/flex-section"

import { getProperty } from "../../lib/utils"

import WhoWeAre from "../../components/about-us-page/who-we-are"
import OurStoryTimeline from "../../components/about-us-page/our-story-timeline"
import OurValues from "../../components/about-us-page/our-values"

const AboutUsPage = ({ pageContext, location }) => {
  const aboutUsPageQueryData = useStaticQuery(ABOUT_US_PAGE_QUERY)
  const title = getProperty(aboutUsPageQueryData, ["seaPage", "seo", ""], "")
  const yoast = getProperty(aboutUsPageQueryData, ["seaPage", "seo"], {})
  const content = getProperty(aboutUsPageQueryData, ["wpPage", "content"], "")

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title={title} location={location} yoast={yoast} />
      {/* <StyledFlexSection> */}
        {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
      {/* </StyledFlexSection> */}
      {/* <WhoWeAre /> */}
      <h1>{title}</h1>
      <OurStoryTimeline />
      <OurValues />
    </Layout>
  )
}

export default AboutUsPage

// ===============
//     STYLES
// ===============
const StyledFlexSection = styled(FlexSection)`
  h3 {
    text-transform: none;
  }
`

// ===============
//      QUERY
// ===============
const ABOUT_US_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "about" }) {
      title
      content
    }
    seaPage(slug: { eq: "about" }) {
      seo {
        og_description
        description
        meta_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_description
        twitter_creator
        twitter_title
      }
    }
  }
`
